var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"label",attrs:{"text":"","color":"transparent"}},[_c('div',{staticClass:"font-weight-regular secondary--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('page_faqs_create_title'))+" ")])])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-capitalize font-weight-regular",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$router.push({ name: 'Faq' })}}},[_c('v-icon',{attrs:{"color":"secondary","left":""}},[_vm._v("$goBack")]),_vm._v(" "+_vm._s(_vm.$t('go_back'))+" ")],1)],1)],1)],1),_c('v-divider'),_c('validation-observer',{ref:"observer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card-text',{staticClass:"custom-card-text"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-md-right pb-0 pb-md-4 category-text",attrs:{"cols":"12","md":"1"}},[_vm._v(" "+_vm._s(_vm.$t('category'))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","flat":"","dense":"","item-value":"id","item-text":"name","items":_vm.categories,"return-object":"","placeholder":_vm.$t('select_category'),"error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.fields),function(field){return _c('v-col',{key:field.name,attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-right label-text",attrs:{"cols":"12","md":"1"}},[_vm._v(" "+_vm._s(field.label)+" ")]),(field.type === 'text')?[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","flat":"","type":field.type,"placeholder":field.placeholder,"error-messages":errors},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)]:_vm._e(),(field.type === 'textarea')?[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"solo":"","label":field.label,"error-messages":errors},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)]:_vm._e()],2)],1)}),1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-right label-text",attrs:{"cols":"12","md":"1"}},[_vm._v(" "+_vm._s(_vm.$t('status'))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"inset":"","color":"primary"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","offset-md":"6"}},[_c('v-btn',{staticClass:"text-capitalize font-weight-regular",attrs:{"type":"submit","disabled":_vm.formStatus,"loading":_vm.formStatus,"color":"primary","depressed":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")])],1)],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }